import React from 'react';
import '../Assests/css/style.css';
import loadingImg from '../Assests/images/loading-img.svg';
import { useTranslation } from 'react-i18next';

const Loading = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="loading-main d-flex flex-wrap flex-column align-items-center justify-content-center">
                <img src={loadingImg} alt={t('Loading Portfolio')} />
                <h1>{t('Loading Portfolio')}</h1>
            </div>
        </>
    );
};

export default Loading;
