import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Define the user's preferred language or fallback to a default language
const userLanguage = navigator.language || navigator.userLanguage;

i18n
  .use(initReactI18next)
  .init({
    resources: {
      // Define your supported locales and their corresponding translation files
      'en-CA': {
        translations: require('./locales/en-CA/translations.json')
      },
      'en-US': {
        translations: require('./locales/en-US/translations.json')
      },
      'fr-CA': {
        translations: require('./locales/fr-CA/translations.json')
      },
      // Add any other supported locales here
    },
    lng: userLanguage, // Set the user's preferred language
    fallbackLng: 'en-CA', // Define a fallback language in case the user's language is not supported
    interpolation: {
      escapeValue: false, // React components handle escaping
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

// Define the list of supported languages
i18n.languages = ['en-CA', 'en-US', 'fr-CA'];

export default i18n;
