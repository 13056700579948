import React, { useState, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import FileNotFoundComponent from './FileNotFound';

import mediaLoading from '../Assests/images/mediaLoading.png';
import { useTranslation } from 'react-i18next';

export const VideoJS = (props) => {

    const { t } = useTranslation();
    const { options, onReady, viewVideoIcon ,viewIcon} = props;
    const [isVideoProcessing, setIsVideoProcessing] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [fileNotFound, setFileNotFound] = useState(false);
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
            const videoElement = document.createElement("video-js");
            if (videoElement && videoRef.current) {
            videoElement.classList.add('vjs-big-play-centered');      
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options, () => {
                // videojs.log('player is ready');
                onReady && onReady(player);
            });

            // Handle errors
            player.on('error', (e) => {
                setFileNotFound(true);
            });

            // Add event listeners to track video loading
            player.on('waiting', () => {
                setIsVideoProcessing(true);
                // You can set a loading state here or display a loading indicator.
            });
  

            // Add event listeners to track video playback
            player.on('play', () => {
                setIsPlaying(true);
            });

            // You could update an existing player in the `else` block here
        }
        } /* else {
            const player = playerRef.current;
            console.log('PlayerRef',playerRef.current);
            if(player && !player.isDisposed()){
            player.autoplay(options.autoplay);
            player.src(options.sources);
            }
        } */
    }, [options, videoRef, onReady]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
                setIsPlaying(false);
            }
    
        };
    }, [playerRef]);

    return (
        <div>
            {fileNotFound ? ( 
            <div>
            <FileNotFoundComponent/>
            {viewIcon}
            </div>
        ): (
            <>
            <div data-vjs-player>
                <div ref={videoRef} />
                {/* Fallback image in case media is not supported */}
            </div>
            {isPlaying &&
                viewVideoIcon
            }
            {isVideoProcessing && !isPlaying && 
                <div className="media-processing">
                    <img src={mediaLoading} alt={t("Media is currently processing")} />
                    <h3>{t("Media is currently processing")}</h3>
                    <p>{t("Check back later to access this file")}</p>
                </div>
            }
            </>
        )}
        </div>
    );
}

export default VideoJS;