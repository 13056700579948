import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import FileNotFoundComponent from './FileNotFound';
import mediaLoading from '../Assests/images/mediaLoading.png';
import { useTranslation } from 'react-i18next';

function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60); // Ensure seconds are rounded down to an integer

    // Use padStart to ensure seconds are always displayed with 2 digits
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}


const AudioWaveform = ({ viewAudioIcon, audioUrl, canvasHeight, itemIndex, audioMimeType,audioUrls,viewIcon }) => {
    // console.log('waveAudioUrl: ',audioUrl);
    // console.log('waveAudioMimeType: ',audioMimeType);
    const [isMediaProcessing, setIsMediaProcessing] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false); // Initialize mute state
    const waveformRef = useRef(null);
    const controlsRef = useRef(null);
    const timelineRef = useRef(null); // Add this line
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0); // Add state to track total duration
    const [volume, setVolume] = useState(0.5); // Initial volume value
    const [fileNotFound, setFileNotFound] = useState(false);
    const wavesurferRef = useRef(null);
    const { t } = useTranslation();

    const handleVolumeChange = e => {
        const { target } = e;
        const newVolume = +target.value;
    
        if (newVolume) {
          setVolume(newVolume);
          wavesurferRef.current.setVolume(newVolume || 1);
          setIsMuted(newVolume === 0.01);
        }
    };
    const handleMuteClick = () => {
        const newMuteState = !isMuted; // Toggle mute state
        setIsMuted(newMuteState); // Update the mute state in React state
        const newVolume = newMuteState ? 0.01 : 1; // Set the volume based on the mute state
        setVolume(newVolume); // Update the volume state
        wavesurferRef.current.setVolume(newVolume); // Set the volume in Wavesurfer
        // Update the volume slider value
        const volumeSlider = document.getElementById(`volume-slider-${itemIndex}`);
        volumeSlider.value = newVolume.toString();
    };

    const handlePlayPauseClick = () => {
        if (wavesurferRef.current.isPlaying()) {
            wavesurferRef.current.pause();
            setIsPlaying(false); // Update state
        } else {
            wavesurferRef.current.play();
            setIsPlaying(true); // Update state
        }
    };

    // Only use MediaElement backend for Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent || '') ||
        /iPad|iPhone|iPod/i.test(navigator.userAgent || '');

    useEffect(() => {
        // Check for audio support in the browser
        if (!window.AudioContext && !window.webkitAudioContext) {
            setFileNotFound(true);
            return;
        }
        if(waveformRef.current) {
        const wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            responsive: true,
            height: canvasHeight, // Set the canvas height
            waveColor: "#aab7cb",
            progressColor: "#2962ff",
            cursorColor: "#2962ff",
            barWidth: 4,
            audioRate: 1,
            backend: isSafari ? 'MediaElement' : 'WebAudio', // Use 'MediaElement' for Safari, 'WebAudio' for others
            normalize: false,
            cursorWidth: 2,
            fillParent: true,
            autoplay: false,
            interact: true,
            dragToSeek: true,
            hideScrollbar: false,
            autoScroll: true,
            autoCenter: true,
            partialRender: true // Use the PeakCache to improve rendering speed of large waveforms.
        });
        // wavesurfer.on('loading', () => {
            // setIsMediaProcessing(true);
            // setTotalDuration(wavesurfer.getDuration());
        // });

        // Handle decoding errors
        wavesurfer.on('error', (error) => {
            setFileNotFound(true);
        });

        wavesurfer.on('ready', () => {
            setIsMediaProcessing(false);
            setTotalDuration(wavesurfer.getDuration());
            wavesurfer.setVolume(volume);
        });

        // Add timeline interaction
        wavesurfer.on('audioprocess', () => {
            const currentTime = wavesurfer.getCurrentTime();
            setCurrentTime(currentTime);
            const duration = wavesurfer.getDuration();
            const percentage = (currentTime / duration) * 100;
            timelineRef.current.style.width = `${percentage}%`;
            if (currentTime >= duration) {
                wavesurfer.pause();
                setIsPlaying(false); // Update state
                setCurrentTime(0);
            }
        });

        wavesurfer.on('finish', () => {
            console.log('Finish');
            wavesurfer.pause();
            wavesurfer.seekTo(0);
            setIsPlaying(false); // Update state
            setCurrentTime(0); // Reset current time to the beginning
        });

        // Create an audio element to check if the MIME type is supported
        const audioElement = document.createElement('audio');
        if (audioElement.canPlayType(audioMimeType)) {
            // Load audio file
            wavesurfer.load(audioUrl)
            .then(() => {
                setIsMediaProcessing(false);
            })
            .catch((error) => {
                setFileNotFound(true);
            });
            if (isSafari && audioMimeType === 'audio/webm') {
                setFileNotFound(true);
            }
        } else {
            // Handle unsupported audio MIME type
            console.error(`Audio MIME type ${audioMimeType} is not supported.`);
            setFileNotFound(true);
        }

        // Store Wavesurfer instance in ref for later access
        wavesurferRef.current = wavesurfer;

        return () => {
            wavesurfer.destroy();
        };
    }
    }, [isSafari, audioUrl, canvasHeight, itemIndex]);



    return <>
        {fileNotFound ? (
            <div>
            <FileNotFoundComponent />
            {viewIcon}
            </div>
        ) : (
            <div className="audioPlayer-main">
                { isMediaProcessing ? (
                    <div className="media-processing">
                        <img src={mediaLoading} alt={t("Media is currently processing")} />
                        <h3>{t("Media is currently processing")}</h3>
                        <p>{t("Check back later to access this file")}</p>
                    </div>
                ) : (
                    <>
                <div className="cover-img audio-player" ref={waveformRef} />
                <div className="custom-controls" ref={controlsRef}>
                    <div className="time-play">
                        <button className="play-button" onClick={handlePlayPauseClick}>
                            {isPlaying ? <i className="icon icon-pause" /> : <i className="icon icon-play" />} {/* Update button icon */}
                        </button>
                        <div className="current-time">
                            {formatTime(currentTime)} | {formatTime(totalDuration)} {/* Display current time and total duration */}
                        </div>
                        {/* <!-- Volume control --> */}

                    </div>
                    <div className="timeline-main">
                        <div className="timeline">
                            <div className="timeline-progress" ref={timelineRef}></div>
                        </div>
                        <div className="volume-btn">
                            <div className="volume-range">
                                <input type="range" id={`volume-slider-${itemIndex}`} name="volume" min="0.01" max="1" step=".025" onChange={handleVolumeChange} defaultValue={volume} />
                                {/* <input type="range" id="volume-slider" min="0" max="1" step="0.01" value={volume} onChange={(e) => handleVolumeChange(e.target.value)} /> */}
                            </div>
                            <button id="mute-button" onClick={(e) => handleMuteClick(e)}  >
                                {isMuted ? <i className="icon icon-mute"></i> : <i className="icon icon-audioOn"></i>}
                            </button>
                        </div>
                        <div className="fullscreen-btn">
                            {viewAudioIcon}
                        </div>
                    </div>
                </div>
                </>
                )}
            </div>
        )}
    </>;
};

export default AudioWaveform;
