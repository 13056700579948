import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AttachmentsItem from './AttachmentsItem';
import RichTextViewer from './RichTextViewer';
import PortalComponent from '../Components/PortalComponent';
import { useTranslation } from 'react-i18next';

const ArtifactComponent = ({
    profileImgUrl,
    profileTitle,
    profileDate,
    postTitle,
    postDescription,
    attachments,
    viewMore,
    showImages,
    showIframe,
    sectionId,
    feedArtifactsLength,
    feedArtifacts
}) => {

    const { t } = useTranslation();
    const [isPortalOpen, setIsPortalOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(parseInt(0));
    const [showAllImages, setShowAllImages] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [activeItem, setActiveItem] = useState([]);
    const [attachmentIndex, setSliderAttachmentIndex] = useState(0);
    const displayedImages = showAllImages ? attachments : attachments?.slice(0, 3);


    // {/* ... Post list slider setting start ... */ }
    const settings = {
        dots: false,
        nav: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3.2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // Adjust the breakpoint as needed
                settings: {
                    slidesToShow: 2.01,
                },
            }
        ],
        initialSlide: selectedImageIndex, // Set the initial slide based on currentIndex
    };

    const settingsMobile = {
        dots: false,
        nav: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2.01,
        slidesToScroll: 1,
        initialSlide: selectedImageIndex, // Set the initial slide based on currentIndex
    };
    /* ... Post list slider setting end ... */

    const feedAttachments = feedArtifacts[selectedItemId].attachments;
    const isAtBeginning = attachmentIndex === 0;
    const isAtEnd = attachmentIndex === feedAttachments.length - 1;
    
    const openPortal = (e,itemId) => {
        e.preventDefault();
        setSliderAttachmentIndex(0);
        setSelectedImageIndex(0);
        setActiveItem([feedArtifacts[itemId].attachments[0]]);
        setSelectedItemId(itemId);
        setIsPortalOpen(true);
        document.body.classList.remove('fullscreen-mode');
    };

    const closePortal = () => {
        setSelectedItemId(0);
        setIsPortalOpen(false);
        setShowAllImages(false);
    };

    /**
     * ARTIFACTS NEXT / PREVIOUS [IN PORTAL]
    */

    // Function to navigate to the next artifact
    const goToNextArtifact = () => {
        if (selectedItemId < feedArtifactsLength - 1) {
            setSelectedImageIndex(0);
            setSliderAttachmentIndex(0);
            setSelectedItemId(parseInt(selectedItemId) + parseInt(1));
            setActiveItem([feedArtifacts[selectedItemId+1].attachments[0]]);
        }
    };

    // Function to navigate to the previous artifact
    const goToPrevArtifact = () => {
        if (selectedItemId > 0) {
            setSelectedItemId(parseInt(selectedItemId) - 1);
            setActiveItem([feedArtifacts[selectedItemId-1].attachments[0]]);
            setSliderAttachmentIndex(0);
            setSelectedImageIndex(0);
        }
    };
    //FOR MOBILE OPEN +VIEW MORE
    const handleViewIconClick = (e,itemId,imageIndex) => {
        e.preventDefault();
        setSelectedItemId(itemId);
        setIsPortalOpen(true);
        setSelectedImageIndex(imageIndex);
        setActiveItem([feedArtifacts[itemId].attachments[imageIndex]]);
        setSliderAttachmentIndex(imageIndex);
        // Add a CSS class to the body element
        document.body.classList.add('fullscreen-mode');
    };

    /**
     * ATTACHMENTS NEXT / PREVIOUS [IN PORTAL]
     */

    // Function to navigate to the next attachment
    const goToNextAttachments = () => {
        if (!isAtEnd) {
            const newIndex = attachmentIndex + 1;
            setSliderAttachmentIndex(newIndex);
            setActiveItem([feedAttachments[newIndex]]);
            setSelectedImageIndex(0);
        }
    };

    // Function to navigate to the previous attachment
    const goToPrevAttachments = () => {
        if (!isAtBeginning) {
            const newIndex = attachmentIndex - 1;
            setSliderAttachmentIndex(newIndex);
            setActiveItem([feedAttachments[newIndex]]);
            setSelectedImageIndex(0);
        }
    };
    // Function to select attachment in portal
    const handleItemClick = (item,selectedIndex) => {
        setSliderAttachmentIndex(selectedIndex);
        setActiveItem([item]);
        setSelectedImageIndex(0);
    };

    /* ============= Mobile ============= */
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    /* ============= Mobile ============= */

    /* ============= Set the first URL as the video source ============= */
    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    };
    /* ============= Set the first URL as the video source ============= */

    const totalCount = attachments.length;

    return (
        <div className="posts">
            <div className="posts-profile-head d-flex flex-wrap align-items-center">
                <div className="posts-profile-img">
                    <img src={profileImgUrl} alt={profileTitle} />
                </div>
                <div className="posts-profile-name flex-grow-1">
                    <h2 className="profile-title">{profileTitle}</h2>
                    <p className="profile-date">{profileDate}</p>
                </div>
            </div>
            <div className="attachments">
                {attachments && attachments.length > 0 ? (
                    attachments.length === 1 ? (
                        <div className="single-img-container">
                            {attachments?.map((attachment, index) => (
                                <div className="image-container" key={attachment.id}> {/* Add key here */}
                                    <div className="image-container-inner">
                                        {/* <div className="image-index">{index + 1}/{totalCount}</div> */}
                                        <AttachmentsItem attachments={[attachment]}  
                                        viewAudioIcon={<button onClick={(e)=>handleViewIconClick(e,sectionId,index)}><i className="icon icon-fullscreen"></i></button>} 
                                        viewIcon={<a href="/#" className="fullscreen-icon" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a> } 
                                        viewVideoIcon={<a href="/#" className="fullscreen-video" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a> } 
                                        showImages={showImages} showIframe={showIframe} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : attachments.length > 3 ? (
                        <div className="multiple-imgs slider-main">
                            <Slider {...settings}>
                                {displayedImages?.map((attachment, index) => (
                                    <div className="image-container" key={attachment.id}> {/* Add key here */}
                                        <div className="image-container-inner">
                                            <div className="image-index">{index + 1}/{totalCount}</div>
                                            <AttachmentsItem attachments={[attachment]}  
                                            handlePlayerReady={handlePlayerReady} 
                                            viewAudioIcon={<button onClick={(e)=>handleViewIconClick(e,sectionId,index)}><i className="icon icon-fullscreen"></i></button>} 
                                            viewIcon={<a href="/#" className="fullscreen-icon" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a>} 
                                            viewVideoIcon={<a href="/#" className="fullscreen-video" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a> } 
                                            showImages={showImages} showIframe={showIframe} />
                                        </div>
                                    </div>
                                ))}

                                {attachments.length > 3 && !showAllImages && (
                                    <div key="view-more" className="image-container">
                                        <div className="image-container-inner">
                                            <div className="more-details"><span><i className="icon icon-add"></i> {attachments.length - displayedImages.length} more</span> {<a href="/#" className="fullscreen-icon" onClick={(e)=>handleViewIconClick(e,sectionId,displayedImages.length)} > </a>}</div>
                                        </div>
                                    </div>
                                )}
                            </Slider>
                        </div>
                    ) : isMobile && attachments.length > 2 ? (
                        // Code to render on mobile screens when there are more than 2 images
                        <div className='multiple-imgs slider-main '>
                            <Slider {...settingsMobile}>
                            {attachments?.map((attachment, index) => (
                                <div className="image-container" key={attachment.id}> {/* Add key here */}
                                    <div className="image-container-inner">
                                        <div className="image-index">{index + 1}/{totalCount}</div>
                                        <AttachmentsItem attachments={[attachment]} 
                                        viewAudioIcon={<button onClick={(e)=>handleViewIconClick(e,sectionId,index)}><i className="icon icon-fullscreen"></i></button>} 
                                        viewIcon={<a href="/#" className="fullscreen-icon" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a>}
                                        viewVideoIcon={<a href="/#" className="fullscreen-video" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a> } 
                                        showImages={showImages} showIframe={showIframe} />
                                    </div>
                                </div>
                            ))}
                            </Slider>
                        </div>
                    ) : (
                        <div className='multiple-imgs d-flex flex-wrap multiple-imgs-2'>
                            {attachments?.map((attachment, index) => (
                                <div className="image-container" key={attachment.id}> {/* Add key here */}
                                    <div className="image-container-inner">
                                        <div className="image-index">{index + 1}/{totalCount}</div>
                                        <AttachmentsItem attachments={[attachment]} 
                                        viewAudioIcon={<button onClick={(e)=>handleViewIconClick(e,sectionId,index)}><i className="icon icon-fullscreen"></i></button>} 
                                        viewIcon={<a href="/#" className="fullscreen-icon" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a>}
                                        viewVideoIcon={<a href="/#" className="fullscreen-video" onClick={(e)=>handleViewIconClick(e,sectionId,index)} ><i className="icon icon-fullscreen"></i></a> } 
                                        showImages={showImages} showIframe={showIframe} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                ) : (
                    null
                )}


            </div>
            <div className="post-content">
                {postTitle && <h3 className="post-title">{postTitle}</h3>}
                {postDescription &&
                <RichTextViewer content={postDescription} viewMore={viewMore} 
                viewMoreShow={<div className="view-more-link" ><Link onClick={(e) => openPortal(e,sectionId)}>{t('View More')}</Link></div>}/>
                }
                {/* {viewMore && 
                    <div className="view-more-link"><Link onClick={(e) => openPortal(e,sectionId)}>View More</Link></div>
                } */}
                {isPortalOpen && 
                <PortalComponent isOpen={isPortalOpen} onClose={closePortal} currentIndex={selectedItemId} onNext={goToNextArtifact} onPrevious={goToPrevArtifact} 
                    feedArtifacts={feedArtifacts} feedArtifactsLength={feedArtifactsLength} selectedImageIndex={selectedImageIndex} 
                    onNextAttachments={goToNextAttachments} onPreviousAttachments={goToPrevAttachments} handleItemClick={handleItemClick}
                    attachmentIndex={attachmentIndex} activeItem={activeItem} isAtBeginning={isAtBeginning} isAtEnd={isAtEnd}/>
                }
            </div>
        </div>
    );
};

export default ArtifactComponent;
