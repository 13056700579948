import React from 'react';
import { Link } from 'react-router-dom';
import '../Assests/css/style.css';
import errorImg from '../Assests/images/error-img.svg';
import { useTranslation } from 'react-i18next';

const TemplateError = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="error-main d-flex flex-wrap flex-column align-items-center justify-content-center">
                <img src={errorImg} alt="Loading Img" />
                <h1>{t("Can’t access this Portfolio")}</h1>
                <p>{t("The link to access this Portfolio has expired")}</p>
                <div className="home-btn d-flex justify-content-center ">
                    <Link className="d-flex align-items-center " to={process.env.REACT_APP_SPACES_URL}><i className="icon icon-left"></i>{t("Go to Home Page")}</Link>
                </div>
            </div>
        </>
    );
};

export default TemplateError;
