import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Notice the import of "Routes" instead of "Switch"
import TemplateError from '../Components/TemplateError';
import Home from '../Pages/Home';
import PageNotFound from '../Components/PageNotFound';

const AppRoutes = () => {
    return (
        <>
            <Router>
            
                <Routes>
                    <Route path="/" element={<TemplateError />} />
                    <Route path="/:portfolioID" element={<Home />} />

                    <Route path="/404" element={<PageNotFound />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
            
            </Router>
        </>
    );
};

export default AppRoutes;
