import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n/config';
import HttpsRedirect from "react-https-redirect";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HttpsRedirect>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </HttpsRedirect>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
