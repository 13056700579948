import React from 'react';
import '../Assests/css/style.css';

const Profile = ({ title, grade, imageSrc, description }) => {
    return (
        <div className="profile-container">
            <div className="profile-head d-flex flex-wrap align-items-center ">
                {imageSrc && <div className="profile-img"> <img src={imageSrc} alt={title} /> </div>}
                <div className="profile-head-name">
                    <h2 className="flex-grow-1 ">{title}</h2>
                    <p className="flex-grow-1 ">{grade}</p>
                </div>
            </div>
            {description &&
                <div className="profile-description">
                    <p>{description}</p>
                </div>
            }
        </div>
    );
}

export default Profile;