import {React, useEffect, useRef, useState } from 'react';

const RichTextViewer = ({ content, viewMore, viewMoreShow }) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isViewMore, setIsViewMore] = useState(false);
    const containerRef = useRef(null);
    
    useEffect(() => {
      const container = containerRef.current;
  
      if (container) {
        const content = container.innerText;
        const maxCharacters = 150; // Adjust this value as needed
  
        if (content.length > maxCharacters) {
          setIsOverflowing(true);
        }
        const shouldShowViewMore = content.split('\n').length > 2;
        setIsViewMore(shouldShowViewMore);
    }
    }, []);

    
    
    const renderRichText = (content) => {
        if (!content) {
            return null;
        }

        return content?.map((element, index) => {
            switch (element.type) {
                case 'paragraph':
                    if(element.content === undefined){
                        return (
                            <p key={index}>
                                <br></br>
                            </p>
                        );
                    }
                    return (
                        <p key={index}>
                            {renderTextElements(element.content)}
                        </p>
                    );
                case 'h1':
                    return (
                        <h1 key={index}>
                            {renderTextElements(element.content)}
                        </h1>
                    );
                case 'ul':
                    return (
                        <ul key={index}>
                            {renderListItems(element.content)}
                        </ul>
                    );
                case 'ol':
                    return (
                        <ol key={index}>
                            {renderListItems(element.content, 'ol')}
                        </ol>
                    );
                case 'bulletList':
                    return (
                        <ul key={index}>
                        {renderListItems(element.content, 'ul')}
                        </ul>
                    );
                case 'orderedList':
                    return (
                        <ol key={index}>
                        {renderListItems(element.content, 'ol')}
                        </ol>
                    );
                default:
                    return null; // Handle other types as needed
            }
        });
    };

    const renderTextElements = (textElements) => {
        if (!textElements) {
            return null;
        }

        return textElements?.map((textItem, index) => {
            const { text, marks } = textItem;
            let markup = text;
            
            if (marks) {
                marks.forEach((mark) => {
                    if (mark.type === 'link' && mark.attrs && mark.attrs.href) {
                        // Render links with 'a' tag
                        markup = `<a href="${mark.attrs.href}">${markup}</a>`;
                    } else if (mark.type === 'bold') {
                        // Wrap text with 'b' tag for bold
                        markup = `<b>${markup}</b>`;
                    } else if (mark.type === 'italic') {
                        // Wrap text with 'i' tag for italic
                        markup = `<i>${markup}</i>`;
                    } else if(mark.type === 'underline'){
                        // Wrap text with 'u' tag for underline
                        markup = `<u>${markup}</u>`;
                    }
                    // Add other mark types as needed
                });
            }
            
            // Render HTML content with dangerouslySetInnerHTML
            return (
                <span
                    key={index}
                    dangerouslySetInnerHTML={{ __html: markup }}
                />
            );
        });
    };

    const renderListItems = (listItems) => {
        if (!listItems) {
            return null;
        }      

        return listItems?.map((listItem, index) => {
            return (
                <li key={index}>
                {listItem.content?.map((itemContent, itemIndex) => {
                    if (itemContent.type === 'text') {
                        // Render text content
                        return (
                        <span key={itemIndex}>{itemContent.text}</span>
                        );
                    } else if (itemContent.type === 'paragraph') {
                        // Render paragraphs
                        return (
                        <p key={itemIndex}>
                            {renderTextElements(itemContent.content)}
                        </p>
                        );
                    }else if (itemContent.type === 'orderedList') {
                        return (
                            <ol key={itemIndex}>
                                {renderListItems(itemContent.content, itemContent.type)}
                            </ol>
                        );
                    }
                    else if (itemContent.type === 'bulletList') {
                        return (
                            <ul key={itemIndex}>
                                {renderListItems(itemContent.content, itemContent.type)}
                            </ul>
                        );
                    }
                    return null; // Handle other types as needed
                    })}
                </li>

            );
        });
    };

    return (
        <div>
            <div className={viewMore === false ? ' ' : 'truncate-two-lines'} ref={viewMore === false ? null : containerRef}>
                {renderRichText(content.content)}
            </div>

            {(isViewMore|| isOverflowing) &&
                viewMoreShow            
            }
        </div>
    );
};

export default RichTextViewer;


