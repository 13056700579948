import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AttachmentsItem from './AttachmentsItem';
import ArtifactComponent from './ArtifactComponent';
import { format } from 'date-fns';
import fullScreenDefaultImg from '../Assests/images/fullscreen_empty.png';

const PortalComponent = ({ isOpen, onClose,currentIndex,onNext,onPrevious,feedArtifacts,feedArtifactsLength,selectedImageIndex,
    onNextAttachments,onPreviousAttachments,handleItemClick,attachmentIndex,activeItem,isAtBeginning,isAtEnd}) => {

    const { t } = useTranslation();
    const playerRef = React.useRef(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 959); // Initialize with correct value

    const artifact = feedArtifacts[currentIndex];
    const attachments = feedArtifacts[currentIndex].attachments;
    const totalCount = attachments.length;

    /* const [activeItem, setActiveItem] = useState([]);
    const [attachmentIndex, setSliderAttachmentIndex] = useState();

    // Calculate whether it's at the beginning or end of the attachment list
    const isAtBeginning = attachmentIndex === 0;
    const isAtEnd = attachmentIndex === attachments.length - 1;

    // Function to navigate to the next artifact
    const goToNextAttachments = () => {
        if (!isAtEnd) {
            const newIndex = attachmentIndex + 1;
            setSliderAttachmentIndex(newIndex);
            setActiveItem([attachments[newIndex]]);
        }
    };

    // Function to navigate to the previous artifact
    const goToPrevAttachments = () => {
        if (!isAtBeginning) {
            const newIndex = attachmentIndex - 1;
            setSliderAttachmentIndex(newIndex);
            setActiveItem([attachments[newIndex]]);
        }
    };
    // Function to select artifact
    const handleItemClick = (item,selectedIndex) => {
        setSliderAttachmentIndex(selectedIndex);
        setActiveItem([item]);
    }; */

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    };

    useEffect(() => {
        const body = document.body;    
        if (isOpen) {
          body.classList.add('portalOpenClass');
        } else {
          body.classList.remove('portalOpenClass');
        }    
        // Add an event listener to update isMobile when the window size changes
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 959);
        };
        // Attach the event listener
        window.addEventListener('resize', handleResize);  
        return () => {
          // Cleanup the class when the component unmounts
          body.classList.remove('portalOpenClass');
          window.removeEventListener('resize', handleResize);
        };
      }, [isOpen]);

    if (!isOpen) {
        return null; // Don't render anything if the portal is not open
    }
    const parsedDate = new Date(artifact.date); // Parse the string into a Date object
    const formattedDate = format(parsedDate, t('date_format'));
    
    return ReactDOM.createPortal(
        <div className="portal">
        <div className="portal-content">
        <div className="detailed-post-main d-flex flex-wrap">
            <div className="detailed-post-content" >
                <div className="detailed-close-icon">
                    <Link onClick={onClose}><i className="icon icon-close"></i></Link>
                </div>

                {Array.isArray(attachments) && attachments.length === 1 ? (
                    <div className="single-mediaItem">
                        {attachments?.map((attachment, index) => (
                            <div className="image-container" key={attachment.id}>
                                <div className="image-container-inner">
                                    <AttachmentsItem showIframe={true} attachments={[attachment]} videoJsOptions={videoJsOptions} handlePlayerReady={handlePlayerReady} />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    Array.isArray(attachments) && attachments.length > 1 ? (
                        <div className="slider-wrapper">
                            <div className="slider-wrap-main">
                                <div className="image-container" key={attachmentIndex}> 
                                    <div className="image-container-inner">
                                        <div className="image-index">{attachmentIndex+1}/{totalCount}</div>
                                        <AttachmentsItem showIframe={true} attachments={activeItem} videoJsOptions={videoJsOptions} handlePlayerReady={handlePlayerReady} />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center thumbnail-slider-wrap align-items-center">{/*  */}
                                <div className="prev-next-btn">
                                    <button
                                        className="prev-post"
                                        onClick={()=> onPreviousAttachments()}
                                        disabled={isAtBeginning}
                                    ><i className="icon icon-left"></i>
                                    </button>
                                </div>
                                <ul className="nav">
                                    {attachments?.map((item, index) => {
                                        const mediaUrl = item.sources && item.sources[0].url;
                                        const mediaType = item.mediaType; // Assuming mediaType is defined in your data
                                        return (
                                            <li
                                                key={index}
                                                className={item === activeItem[0] ? 'active' : ''}
                                                onClick={() => handleItemClick(item,index)}
                                            >
                                                {mediaType === 0 ? ( // Image
                                                    <div className="image-container">
                                                        <div className="image-container-inner">
                                                        <img className="cover-img" src={mediaUrl} alt="Cover Media" />
                                                        </div>
                                                    </div>
                                                    ) : mediaType === 1 ? ( // Video
                                                    <div className="image-container">
                                                        <div className="image-container-inner">
                                                        <i className="icon icon-video"></i>
                                                        </div>
                                                    </div>
                                                    ) : mediaType === 2 ? ( // Audio
                                                    <div className="image-container">
                                                        <div className="image-container-inner">
                                                        <i className="icon icon-audio"></i>
                                                        </div>
                                                    </div>
                                                    ) : mediaType === 3 ? ( // Document (e.g., PDF, Word)
                                                    <div className="image-container">
                                                        <div className="image-container-inner">
                                                        <i className="icon icon-file"></i>
                                                        </div>
                                                    </div>
                                                    ) : ( // Default case, including case 4 (Other)
                                                    <div className="image-container">
                                                        <div className="image-container-inner">
                                                        <i className="icon icon-link"></i>
                                                        </div>
                                                    </div>
                                                )}
                                            </li>                                        
                                        );
                                    })}
                                </ul>

                                <div className="prev-next-btn">
                                    <button
                                        className="next-post"
                                        onClick={() => onNextAttachments()}
                                        disabled={isAtEnd}
                                    >
                                        <i className="icon icon-right"></i>
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    ) : (
                         <div className="single-mediaItem">
                            <div className="image-container">
                                <div className="image-container-inner">
                                    <img className="defult-img" src={fullScreenDefaultImg} alt="Full Screen" />
                                </div>
                            </div>
                        </div>
                    )
                )}


            </div>
            <div className="detailed-post-sidebar">
                <div className="post-sidebar-head d-flex flex-wrap justify-content-between align-items-center">
                    <div className="prev-next-btn">
                        {isMobile && (
                            <Link onClick={onClose}><i className="icon icon-close"></i></Link>
                        )}
                        <button
                            className="prev-post"
                            onClick={onPrevious}
                            disabled={currentIndex === 0}
                        >
                            <i className="icon icon-left"></i>
                        </button>
                    </div>
                    <h3>{t('Post')}</h3>
                    <div className="prev-next-btn">
                        <button
                            className="next-post"
                            onClick={onNext}
                            disabled={currentIndex === feedArtifactsLength - 1}
                        >
                            <i className="icon icon-right"></i>
                        </button>
                    </div>
                </div>
                <div className="post-sidebar-content">
                    <ArtifactComponent
                        profileImgUrl={artifact.displayImage.sources[0].url}
                        profileTitle={artifact.ownerName}
                        profileDate={formattedDate}
                        attachments={isMobile ? artifact.attachments : []} // Use a ternary operator here
                        postTitle={artifact.title}
                        postDescription={artifact.body}
                        viewMore={false}
                        viewIcon={true}
                        showImages={true}
                        sectionId={currentIndex}
                        feedArtifactsLength={feedArtifactsLength}
                        feedArtifacts={feedArtifacts}
                        isOpen={isOpen}
                        onClose={onClose}
                        // viewIcon={<Link className="fullscreen-icon" onClick={handleViewMoreClick}><i className="icon icon-fullscreen"></i></Link>}
                    />
                </div>
            </div>
        </div>
        </div>
        </div>
        ,
        document.body // Render the portal content inside the <body> element
    );
};

export default PortalComponent;
