import * as React from "react";
import { useVirtual as useVirtualImpl } from 'react-virtual';



// TODO Options type should be exported from react-virtual to replace this
const defaultKeyExtractor = (index) => index;

export const useVirtual = ({
  updateSize = true,
  ...options
}) => {
  const measureRefCacheRef = React.useRef({});
  const elCacheRef = React.useRef({});

  const update = (key, el) => {
    if (updateSize) {
      measureRefCacheRef.current[key](el);
    }
  };
  const updateRef = React.useRef(update);
  updateRef.current = update;

  const roRef = React.useRef(
    new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const el = entry.target;
        const attribute = "data-key";
        const key = el.getAttribute(attribute);

        if (key === null) {
          throw new Error(`Value not found, for '${attribute}' attribute`);
        }

        const htmlEl = el;

        updateRef.current(key, htmlEl);
      });
    })
  );

  React.useEffect(() => {
    const ro = roRef.current;
    return () => {
      ro.disconnect();
    };
  }, []);

  const { size, keyExtractor = defaultKeyExtractor } = options;

  const cachedMeasureRefWrappers = React.useMemo(() => {
    const makeMeasureRefWrapperForItem = (key) => (
      el
    ) => {
      if (elCacheRef.current[key]) {
        roRef.current.unobserve(elCacheRef.current[key]);
      }

      if (el) {
        // sync
        updateRef.current(key, el);
        // observe
        roRef.current.observe(el);
      }

      elCacheRef.current[key] = el;
    };

    const refsAcc = {};

    for (let i = 0; i < size; i++) {
      const key = keyExtractor(i);

      refsAcc[key] = makeMeasureRefWrapperForItem(key);
    }

    return refsAcc;
  }, [size, keyExtractor]);

  const rowVirtualizer = useVirtualImpl(options);

  const virtualItems = rowVirtualizer.virtualItems?.map((item) => {
    measureRefCacheRef.current[item.key] = item.measureRef;

    return {
      ...item,
      measureRef: cachedMeasureRefWrappers[item.key]
    };
  });

  return { ...rowVirtualizer, virtualItems };
};

export const Virtualizer = useVirtual;
