import React from 'react';
import noFile from '../Assests/images/noFile.png';
import { useTranslation } from 'react-i18next';

function PageNotFound() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="media-processing">
          <img src={noFile} alt={t("File not found")} />
          <h3>{t("File not found")}</h3>
          <p>{t("Check back later to access this file")}</p>
      </div>
    </div>
  );
}

export default PageNotFound;
