import React, { useState } from 'react';
import '../Assests/css/style.css';

const TemplateName = ({ text, iconClass, sectionText }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(prevMobileMenuOpen => !prevMobileMenuOpen);

        const body = document.body;
        // Add or remove a class to the body element
        if (!mobileMenuOpen) {
            body.classList.add('mobile-menu-open');
        } else {
            body.classList.remove('mobile-menu-open');
        }
    };
    return (
        <>
            <div className="template-head-main">
                <div className="template-name-head">
                    <h4>{text}</h4>
                </div>
                <div
                    className="d-flex  align-items-center mobile-menu"
                    onClick={toggleMobileMenu}
                >
                    <i className={`icon ${iconClass}`}></i>
                    {sectionText}
                </div>
            </div>
        </>
    );
};

export default TemplateName;
