import React  from 'react';
import errorImg from '../Assests/images/error-img.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PageNotFound() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="no-work">
          <img src={errorImg} alt={t("404_title")} />
          <h4>{t("404_title")}</h4>
          <p>{t("404_description")}</p>
          <div className="home-btn d-flex justify-content-center ">
              <Link className="d-flex align-items-center " to={process.env.REACT_APP_SPACES_URL}><i className="icon icon-left"></i>{t("Go Back")}</Link>
          </div>
      </div>
    </div>
  );
}

export default PageNotFound;
